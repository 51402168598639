import { useState } from 'react';

export const useTabChange = (defaultActiveTab: string) => {
	const [activeKey, setActiveKey] = useState<string>(window.location.hash.substring(1) || defaultActiveTab);

	const handleTabChange = (activeKey: string) => {
		window.history.replaceState('', '', '#' + activeKey);
		setActiveKey(activeKey);
	};

	return { activeKey, handleTabChange };
};
