import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { Skeleton } from '@/components/skeleton';
import { TailwindColorRenderer } from '@/components/tailwind-color-renderer';
import { formatDate } from '@/utils';
import { Link, useParams } from 'react-router-dom';
import { Currency, NavigateBack } from '../../../../components';
import { useApi } from '../../../../hooks/use-api';
import { getPass } from '../api';
import { ProductPassPaymentStatusBadge } from '../components/product-pass-payment-status-badge';
import { ProductPassProgressBar } from '../components/product-pass-progress-bar';
import { ProductPassStatusBadge } from '../components/product-pass-status-badge';
import { ProductPass } from '../types';

export const ProductPassDetailPage = () => {
	const { passId } = useParams<{ passId: string }>();
	const { data: pass, isLoading } = useApi<ProductPass>(getPass, { id: passId });

	if (isLoading) {
		return (
			<div className="flex flex-col gap-4">
				<Skeleton className="h-12" />
				<Skeleton className="h-36" />
			</div>
		);
	}

	if (!pass) {
		return <p className="mt-4 text-center text-muted-foreground">Nessun pacchetto trovato</p>;
	}

	return (
		<div className="pb-4">
			<NavigateBack to={'/products#mine'} className="mb-4" />
			<TailwindColorRenderer color={pass.product.color} className="mb-2 h-3 w-10 rounded-2xl" />
			<Heading className="mb-4">{pass.product.name}</Heading>
			<Inner pass={pass} />
		</div>
	);
};

const Inner = (props: { pass: ProductPass }) => {
	return (
		<div className="space-y-4">
			<div className="space-x-2">
				<ProductPassStatusBadge pass={props.pass} />
				{props.pass.operation && <ProductPassPaymentStatusBadge payment={props.pass.operation} />}
			</div>

			<div className="flex justify-between">
				<div className="space-y-0.5">
					<p className="text-sm text-muted-foreground">Data di inizio</p>
					<p className="font-medium">{formatDate(props.pass.startDate)}</p>
				</div>
				<div className="space-y-0.5">
					<p className="text-sm text-muted-foreground">Data di fine</p>
					<p className="font-medium">{formatDate(props.pass.expirationDate)}</p>
				</div>
			</div>

			<ProductPassProgressBar pass={props.pass} />

			<div className="space-y-0.5">
				<p className="text-sm text-muted-foreground">Data di attivazione</p>
				<p className="font-medium">{formatDate(props.pass.activatedDate)}</p>
			</div>

			{props.pass.product.description && (
				<div className="space-y-0.5">
					<p className="text-sm text-muted-foreground">Descrizione</p>
					<p>{props.pass.product.description}</p>
				</div>
			)}

			{props.pass.operation && (
				<div className="space-y-4 border-t border-solid border-muted pt-4">
					<div className="space-y-0.5">
						<p className="text-sm text-muted-foreground">Importo</p>
						<p className="font-medium">
							<Currency value={props.pass.operation.amount} />
						</p>
					</div>

					{props.pass.operation.cashed && props.pass.operation.cashedDate && (
						<div className="space-y-0.5">
							<p className="text-sm text-muted-foreground">Data del pagamento</p>
							<p className="font-medium">{formatDate(props.pass.operation.cashedDate)}</p>
						</div>
					)}

					{!props.pass.operation.cashed && props.pass.operation.expectedCashedDate && (
						<div className="space-y-0.5">
							<p className="text-sm text-muted-foreground">Data prevista per il pagamento</p>
							<p className="font-medium">{formatDate(props.pass.operation.expectedCashedDate)}</p>
						</div>
					)}

					{!props.pass.operation.cashed && (
						<div className="border-t border-solid border-muted pt-4">
							<div className="flex justify-center">
								<Button asChild>
									<Link to={'/payments'}>Vai al pagamento</Link>
								</Button>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};
