import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { Skeleton } from '@/components/skeleton';
import { TailwindColorRenderer } from '@/components/tailwind-color-renderer';
import { useNavigate, useParams } from 'react-router-dom';
import { Currency, NavigateBack } from '../../../../components';
import { useSeason } from '../../../../context/season';
import { useApi } from '../../../../hooks/use-api';
import { getProduct } from '../api';
import { ProductPassActivationButton } from '../components/pass-activation/product-pass-activation-button';
import { ProductConfigurationRenderer } from '../components/product-configuration-renderer';
import { Product } from '../types';
import { isProductAvailable } from '../utils/is-product-available';

export const ProductDetailPage = () => {
	const { currentSeason } = useSeason();
	const { productId } = useParams<{ productId: string }>();
	const { data: product, isLoading } = useApi<Product>(getProduct, { id: productId, season: currentSeason });

	if (isLoading) {
		return (
			<div className="flex flex-col gap-4">
				<Skeleton className="h-12" />
				<Skeleton className="h-36" />
			</div>
		);
	}

	if (!product) {
		return (
			<div>
				<NavigateBack to={'/products#new'} className="mb-4" />
				<p className="mt-4 text-center text-muted-foreground">Nessun pacchetto trovato</p>
			</div>
		);
	}

	return (
		<div className="pb-4">
			<NavigateBack to={'/products#new'} className="mb-4" />
			<TailwindColorRenderer color={product.color} className="mb-2 h-3 w-10 rounded-2xl" />
			<Heading className="mb-4">{product.name}</Heading>

			<Inner product={product} />
		</div>
	);
};

const Inner = (props: { product: Product }) => {
	const navigate = useNavigate();

	const isAvailable = isProductAvailable(props.product);

	return (
		<div className="space-y-4">
			<div className="space-y-0.5">
				<p className="text-sm text-muted-foreground">Periodo di validità</p>
				<p>
					<ProductConfigurationRenderer product={props.product} />
				</p>
			</div>

			{props.product.description && (
				<div className="space-y-0.5">
					<p className="text-sm text-muted-foreground">Descrizione</p>
					<p>{props.product.description}</p>
				</div>
			)}

			{props.product.configuration.payment && (
				<div className="space-y-4 border-t border-solid border-muted pt-4">
					<div className="space-y-0.5">
						<p className="text-sm text-muted-foreground">Prezzo</p>
						<p className="text-xl font-bold tracking-tighter">
							<Currency value={props.product.configuration.payment.amount} />
						</p>
					</div>
				</div>
			)}

			<div className="space-y-4 border-t border-solid border-muted pt-4">
				<div className="flex justify-center">
					{isAvailable ? (
						<ProductPassActivationButton
							product={props.product}
							onPassCreated={() => {
								navigate(`/products#mine`);
							}}
						/>
					) : (
						<Button disabled variant="secondary">
							Pacchetto scaduto
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};
