import { Heading } from '@/components/heading';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/tabs';
import { useOrgPerson } from '../../../../context/org-person/use-org-person';
import { EventList } from '../components/list/event-list';
import { StatisticsTab } from '../components/statistics/statistics';
import { PeriodProvider } from '../contexts/period.context';

export const Calendar = () => {
	const { orgPerson } = useOrgPerson();

	return (
		<div className={'hide-scrollbar pb-4'}>
			<Heading className="mb-4">Calendario</Heading>

			<PeriodProvider>
				<Tabs defaultValue="calendar">
					<TabsList className="grid w-full grid-cols-2">
						<TabsTrigger value="calendar">Eventi</TabsTrigger>
						<TabsTrigger value="stats">Statistiche</TabsTrigger>
					</TabsList>
					<TabsContent value="calendar">
						<EventList orgPersonId={orgPerson.orgPersonId} organizationId={orgPerson.organizationId} />
					</TabsContent>
					<TabsContent value="stats">
						<StatisticsTab />
					</TabsContent>
				</Tabs>
			</PeriodProvider>
		</div>
	);
};
