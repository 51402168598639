import { Heading } from '@/components/heading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmptyList, TrancheCard } from '../../../components';
import { SeasonSelector } from '../../../components/season/season-selector';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { useSettings } from '../../../context/settings';
import { useTranches } from '../../../context/tranches';
import { sortedTranches } from './utils';

export const Payments = () => {
	const navigate = useNavigate();

	const { orgPerson } = useOrgPerson();
	const { settings } = useSettings();
	const { tranches, updateContext } = useTranches();

	const initLayout = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		void updateContext();
		initLayout();
	}, []);

	return (
		<div className="hide-scrollbar relative flex flex-col gap-4">
			<div>
				<div className="tw-items-center flex justify-between">
					<Heading>Pagamenti</Heading>
					<SeasonSelector />
				</div>

				<div className="mb-4 mt-1">
					{settings.paymentsDescription && (
						<div
							className="html-content-preview text-sm"
							dangerouslySetInnerHTML={{ __html: settings.paymentsDescription }}
						/>
					)}
				</div>
			</div>

			<div className="pb-4">
				{tranches.length ? (
					<div className="flex flex-col gap-4">
						{sortedTranches(tranches).map(payment => (
							<TrancheCard
								key={payment._id}
								tranche={payment}
								athlete={orgPerson}
								onClick={() => {
									navigate(`/payments/${payment._id}`, {
										state: {
											payment,
										},
									});
								}}
							/>
						))}
					</div>
				) : (
					<EmptyList />
				)}
			</div>
		</div>
	);
};
