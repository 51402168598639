import { Heading } from '@/components/heading';
import { useEffect, useState } from 'react';
import { getPostsFeed, Post } from '../../../api/club/posts-feed';
import { LoadingLayout } from '../../../components';
import { OrgPerson } from '../../../context/org-person/types';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { PostList } from './posts';

export const Feed = () => {
	const { orgPerson } = useOrgPerson();
	const [feed, setFeed] = useState<Post[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const loadFeed = async (orgPerson: OrgPerson) => {
		setIsLoading(true);

		const groups = orgPerson.groups?.map(g => g.groupId) || [];

		try {
			const resp = await getPostsFeed(orgPerson.roles || [], groups);
			setFeed(resp);
		} catch (e) {
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		void loadFeed(orgPerson);
	}, []);

	return (
		<div className={'hide-scrollbar'}>
			<Heading className="mb-4">Bacheca</Heading>

			{isLoading ? <LoadingLayout /> : <PostList posts={feed} />}
		</div>
	);
};
