import { wait } from '../../../../@/utils/wait';
import { apiClient } from '../../../../api/client';
import { Product, ProductPass, ProductTimeConfiguration } from '../types';
import { passesMock, productsMock } from './mocks';

const USE_MOCK = false;

export const getAvailableProducts = async () => {
	if (!USE_MOCK) {
		return apiClient('financial').get<Product[]>(`org-person-products`);
	}

	await wait(400);
	return {
		data: productsMock,
	};
};

export const getProduct = async (params: { id: string }) => {
	if (!USE_MOCK) {
		return apiClient('financial').get<Product[]>(`org-person-products/${params.id}`);
	}

	await wait(400);
	return {
		data: productsMock.find(product => product.id === params.id),
	};
};

export const getMyPasses = async (params: { season: string }) => {
	if (!USE_MOCK) {
		return apiClient('financial').get<ProductPass[]>(`org-person-passes`, {
			headers: {
				'x-gle-current-season': params.season,
				'x-gle-selected-season': params.season,
			},
		});
	}

	await wait(400);
	return {
		data: passesMock,
	};
};

export const getPass = async (params: { id: string; season: string }) => {
	if (!USE_MOCK) {
		return apiClient('financial').get<ProductPass[]>(`org-person-passes/${params.id}`, {
			headers: {
				'x-gle-current-season': params.season,
				'x-gle-selected-season': params.season,
			},
		});
	}

	await wait(400);
	return {
		data: passesMock.find(pass => pass.id === params.id),
	};
};

export const createPass = async (data: { productId: string; startDate: string }, params: { season: string }) => {
	if (!USE_MOCK) {
		return apiClient('financial').post<void>(`org-person-passes/create`, data, {
			headers: {
				'x-gle-current-season': params.season,
				'x-gle-selected-season': params.season,
			},
		});
	}

	await wait(400);
	return;
};

export const getCalculatedExpirationDate = async (
	data: {
		configuration: ProductTimeConfiguration;
		startDate: string;
	},
	params: { season: string }
) => {
	if (!USE_MOCK) {
		return apiClient('financial').post<{ startDate: string; expirationDate: string }>(
			`passes/calculate-dates`,
			data,
			{
				headers: {
					'x-gle-current-season': params.season,
					'x-gle-selected-season': params.season,
				},
			}
		);
	}

	await wait(400);
	throw new Error('Not implemented');
};
