import { TObjTranche } from '@golee/gle-types';
import { DateTime } from 'luxon';

export const sortedTranches = (tranches: TObjTranche[]): TObjTranche[] => {
	const notCashed = tranches.filter(tranche => !tranche.cashed);
	const cashed = tranches.filter(tranche => tranche.cashed);

	notCashed.sort((a, b) => {
		if (a.expected_cashed_date && b.expected_cashed_date) {
			const dateA = DateTime.fromFormat(a.expected_cashed_date, 'dd/MM/yyyy');
			const dateB = DateTime.fromFormat(b.expected_cashed_date, 'dd/MM/yyyy');

			if (dateA < dateB) {
				return -1;
			} else {
				return 1;
			}
		}
		return 0;
	});

	cashed.sort((a, b) => {
		if (a.cashed_date && b.cashed_date) {
			const dateA = DateTime.fromFormat(a.cashed_date, 'dd/MM/yyyy');
			const dateB = DateTime.fromFormat(b.cashed_date, 'dd/MM/yyyy');

			return dateB.diff(dateA).as('milliseconds');
		}
		return 0;
	});

	return [...notCashed, ...cashed];
};
