import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { RangeType } from '../../../../components/period-filter';
import { getEventsByParticipant } from '../api';
import { usePeriod } from '../contexts/period.context';
import { CalendarEvent } from '../types';

const LIMIT = 100; // TODO: check this

export const useCalendarEvents = (orgPersonId: string, organizationId: string) => {
	const { start, end, rangeType, onChange } = usePeriod();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [events, setEvents] = useState<CalendarEvent[]>([]);

	const fetchData = async (_start: DateTime, _end: DateTime) => {
		setIsLoading(true);

		try {
			const { data } = await getEventsByParticipant(orgPersonId, organizationId, {
				fromDate: _start.startOf('day').toISO(),
				toDate: _end.endOf('day').toISO(),
				limit: LIMIT,
			});

			setEvents(data);
		} catch (err) {
		} finally {
			setIsLoading(false);
		}
	};

	const onPeriodChange = (_start: DateTime, _end: DateTime, rangeType?: RangeType) => {
		onChange(_start, _end, rangeType);
		fetchData(_start, _end);
	};

	useEffect(() => {
		void fetchData(start, end);
	}, []);

	return {
		start,
		end,
		rangeType,
		events,
		onPeriodChange,
		isLoading,
	};
};
