import { Heading } from '@/components/heading';
import { useConan } from '@golee/gle-conan-tracker';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigateBack } from '../../../../../components';
import { useOrgPerson } from '../../../../../context/org-person';
import { notifyDataChange } from '../../utils/notify-data-changed';
import { AddressForm } from './forms/address-form';
import { ContactsForm } from './forms/contacts-form';

export const ProfileContactsPage = () => {
	const navigate = useNavigate();
	const { trackEvent } = useConan();
	const { orgPerson, refresh: refreshOrgPersonContext } = useOrgPerson();

	const [currentEditingSection, setCurrentEditingSection] = useState<'contacts' | 'address' | undefined>(undefined);

	const notifyChangesToOrganization = (updatedValues: { [key: string]: boolean | undefined }) => {
		const updatedValuesAsArray = Object.keys(updatedValues).filter(key => !!updatedValues[key]);

		if (updatedValuesAsArray.length) {
			notifyDataChange(orgPerson, updatedValuesAsArray as string[]);
			void trackEvent('family.orgPerson-changed-data-from-app', {
				values: updatedValuesAsArray.join(', '),
				section: 'contacts',
			});
		}
	};

	return (
		<div className="pb-4">
			<NavigateBack to={'/profile'} className="mb-4" />

			{currentEditingSection !== 'address' && (
				<>
					<Heading level={2} className="mb-4">
						Contatti
					</Heading>
					<ContactsForm
						orgPersonId={orgPerson.orgPersonId}
						season={orgPerson.season}
						onCancel={() => {
							navigate('/profile');
						}}
						onComplete={() => {
							refreshOrgPersonContext();
							navigate('/profile');
						}}
						initialValues={{
							email: orgPerson.contacts?.primaryEmail,
							phone: orgPerson.personMetadata.contacts?.primaryPhone || undefined,
						}}
						allowEdit={!currentEditingSection || currentEditingSection === 'contacts'}
						onEditStatusChanged={isEdit => {
							setCurrentEditingSection(isEdit ? 'contacts' : undefined);
						}}
					/>
				</>
			)}

			{currentEditingSection !== 'contacts' && (
				<>
					<Heading level={2} className="my-4">
						Indirizzo
					</Heading>

					<AddressForm
						orgPersonId={orgPerson.orgPersonId}
						season={orgPerson.season}
						onCancel={() => {
							navigate('/profile');
						}}
						onComplete={updatesValues => {
							notifyChangesToOrganization(updatesValues);
							refreshOrgPersonContext();
							navigate('/profile');
						}}
						initialValues={orgPerson.personMetadata.address || {}}
						allowEdit={!currentEditingSection || currentEditingSection === 'address'}
						onEditStatusChanged={isEdit => {
							setCurrentEditingSection(isEdit ? 'address' : undefined);
						}}
					/>
				</>
			)}
		</div>
	);
};
