import { Post } from '../../../../api/club/posts-feed';
import { EmptyList } from '../../../../components';
import { PostItem } from './post-item';

export const PostList = ({ posts }: { posts: Post[] }) => {
	if (!posts.length) {
		return <EmptyList />;
	}

	return (
		<div className="pb-4">
			<div className={'flex flex-col gap-4'}>
				{posts.map((post, i) => (
					<PostItem key={i} post={post} />
				))}
			</div>
		</div>
	);
};
