import { Heading } from '@/components/heading';
import { Spinner } from '@/components/spinner';
import { useEffect, useState } from 'react';
import { useOrgPerson } from '../../../../context/org-person/use-org-person';
import { getMembershipCardSettings } from '../api';
import { MembershipCard } from '../components/membership-card';
import { CompleteMembershipCard } from '../types';
import { createMembershipCardData } from '../utils/create-membership-card-data';

export const MembershipCardPage = () => {
	const { orgPerson, organization } = useOrgPerson();

	const isDisabled = orgPerson.personMetadata.membershipCard?.isDisabled;

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [membershipCardData, setMembershipCardData] = useState<CompleteMembershipCard>();

	const getMembershipCardData = async () => {
		try {
			setIsLoading(true);
			const { data } = await getMembershipCardSettings();
			setMembershipCardData(
				createMembershipCardData(data, { organization, season: orgPerson.season, orgPerson })
			);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getMembershipCardData();
	}, []);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<div>
			<div className="mb-4">
				<Heading>Tessera</Heading>
				{isDisabled && (
					<div className="mt-1 text-sm text-muted-foreground">
						<p>Questa tessera è disabilitata</p>
					</div>
				)}
			</div>
			{membershipCardData && (
				<div className="flex justify-center">
					<MembershipCard isDisabled={isDisabled} data={membershipCardData} />
				</div>
			)}
		</div>
	);
};
