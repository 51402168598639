import { DateTime } from 'luxon';

export const calculateRemainingDays = (startDate: string, expirationDate: string) => {
	const start = DateTime.fromISO(startDate).startOf('day');
	const expiration = DateTime.fromISO(expirationDate).startOf('day');
	const today = DateTime.now().startOf('day');

	if (start > today) {
		return expiration.diff(start, 'days').days;
	}

	if (expiration < today) {
		return 0;
	}

	return expiration.diff(today, 'days').days;
};
