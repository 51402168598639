import { Button } from '@/components/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/dropdown-menu';
import { cn } from '@/lib/utils';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { DateTime } from 'luxon';
import { useState } from 'react';

export type RangeType = 'week' | 'month' | 'year';

type PeriodFilterProps = {
	rangeType?: RangeType;
	from: DateTime;
	to: DateTime;
	allowedRanges?: RangeType[];
	onChange?: (from: DateTime, to: DateTime, range: RangeType) => void;
};

export const PeriodFilter = (props: PeriodFilterProps) => {
	const [rangeType, setRangeType] = useState<RangeType>(props.rangeType || 'week');

	const onPeriodChange = (value: RangeType) => {
		setRangeType(value);

		switch (value) {
			case 'week':
				{
					props.onChange?.(
						DateTime.now().setZone('utc').startOf('week'),
						DateTime.now().setZone('utc').endOf('week'),
						'week'
					);
				}
				break;
			case 'month':
				{
					props.onChange?.(
						DateTime.now().setZone('utc').startOf('month'),
						DateTime.now().setZone('utc').endOf('month'),
						'month'
					);
				}
				break;
			case 'year':
				{
					props.onChange?.(
						DateTime.now().setZone('utc').startOf('year'),
						DateTime.now().setZone('utc').endOf('year'),
						'year'
					);
				}
				break;
		}
	};

	const options = [
		{
			label: 'Settimana',
			value: 'week',
		},
		{
			label: 'Mese',
			value: 'month',
		},
		{
			label: 'Anno',
			value: 'year',
		},
	].filter(range => props.allowedRanges?.includes(range.value as RangeType)) as { value: RangeType; label: string }[];

	const onPrev = () => {
		const from = props.from.minus({ [rangeType]: 1 }).startOf(rangeType);
		const to = props.to.minus({ [rangeType]: 1 }).endOf(rangeType);
		props.onChange?.(from, to, rangeType);
	};

	const onNext = () => {
		const from = props.from.plus({ [rangeType]: 1 }).startOf(rangeType);
		const to = props.to.plus({ [rangeType]: 1 }).endOf(rangeType);
		props.onChange?.(from, to, rangeType);
	};

	return (
		<div className="flex items-center">
			<DropdownMenu>
				<DropdownMenuTrigger>
					<Button variant="outline" className="inline-flex items-center rounded-r-none border-r-0">
						<div>
							<span>{options.find(o => o.value === rangeType)?.label}</span>
						</div>
						<ChevronDownIcon size={16} />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent>
					<DropdownMenuLabel>Seleziona periodo</DropdownMenuLabel>
					<DropdownMenuSeparator />
					{options.map((option, index) => (
						<DropdownMenuItem
							key={`period-${index}`}
							className={cn(option.value === rangeType && 'font-bold')}
							onClick={() => {
								onPeriodChange(option.value);
							}}>
							{option.label}
						</DropdownMenuItem>
					))}
				</DropdownMenuContent>
			</DropdownMenu>

			<div className="inline-flex h-11 w-full items-center justify-center gap-2 rounded-lg rounded-l-none border border-solid border-border px-1 text-sm">
				<div onClick={onPrev} className="cursor-pointer rounded p-1 hover:bg-zinc-200">
					<ChevronLeftIcon size={16} />
				</div>
				<p className="text-sm font-medium capitalize">
					<RangeLabel range={rangeType} from={props.from} to={props.to} />
				</p>
				<div onClick={onNext} className="cursor-pointer rounded p-1 hover:bg-zinc-200">
					<ChevronRightIcon size={16} />
				</div>
			</div>
		</div>
	);
};

const RangeLabel = (props: { range: RangeType; from: DateTime; to: DateTime }) => {
	switch (props.range) {
		case 'week': {
			return (
				<>
					{props.from.toFormat('ccc dd/MM')} - {props.to.toFormat('ccc dd/MM')}
				</>
			);
		}
		case 'month': {
			return <>{props.from.toFormat('MMMM yyyy')}</>;
		}
		case 'year': {
			return <>{props.from.toFormat('yyyy')}</>;
		}
	}
};
