import { Badge } from '@/components/badge';
import { Button } from '@/components/button';
import { Drawer, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle } from '@/components/drawer';
import { useToast } from '@/components/toast';
import { useConan } from '@golee/gle-conan-tracker';
import { Trash2 } from 'lucide-react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { deleteDocument, DocumentModel } from '../../../../api/documents/documents';
import { useModal } from '../../../../hooks/use-modal';
import { MimeTypeIcon } from '../mime-type-icon';

export const DocumentCard = ({ document, onDelete }: { document: DocumentModel; onDelete: () => void }) => {
	const deleteDocumentModal = useModal();
	const { toast } = useToast();
	const { trackEvent } = useConan();

	const deleteSelectedDocument = async (_id: string) => {
		try {
			await deleteDocument(_id);
			onDelete();
			deleteDocumentModal.closeModal();
			toast({
				title: 'Documento eliminato con successo',
				description: 'Il documento è stato eliminato correttamente',
				variant: 'success',
			});
			trackEvent('family.document_deleted', {
				document_id: _id,
				document_url: document.url,
			});
		} catch (error) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		}
	};

	return (
		<>
			<Link
				className="border-b border-solid border-slate-100 p-4 transition-colors hover:cursor-pointer hover:bg-slate-50"
				to={`/documents/${document._id}`}
				state={{ document }}>
				<div className="mb-3 flex justify-end">
					<div className="flex gap-2">
						{document.type_entity === 'org_person' && !document.seenFromOrgPerson && (
							<Badge variant={'secondary'}>Nuovo</Badge>
						)}

						{document.signature?.status === 'pending' && <Badge variant={'warning'}>Da firmare</Badge>}

						{document.signature?.status === 'completed' && <Badge variant={'success'}>Firmato</Badge>}
					</div>
				</div>

				<div className={'mt-1 flex items-center justify-between gap-3'}>
					<div className="flex items-center gap-2">
						<MimeTypeIcon mimeType={document.mimeType} />

						<div className="flex flex-col gap-1">
							<p className="text-sm">{document.name}</p>

							<p className="text-xs text-gray-400">
								{document.createdAt
									? DateTime.fromISO(document.createdAt).toFormat('HH:mm dd/MM/yyyy')
									: ''}
							</p>
						</div>
					</div>

					{document.uploadedBy === 'organization_person' && (
						<Button
							variant={'ghost'}
							size={'icon'}
							onClick={e => {
								e.preventDefault();
								deleteDocumentModal.openModal();
							}}>
							<Trash2 size={16} color="red" />
						</Button>
					)}
				</div>
			</Link>

			<Drawer
				open={deleteDocumentModal.isModalVisible}
				onOpenChange={status => (!status ? deleteDocumentModal.closeModal() : deleteDocumentModal.openModal())}>
				<DrawerContent>
					<DrawerHeader>
						<DrawerTitle>Sei sicuro?</DrawerTitle>
						<DrawerDescription>L'eliminazione del documento è irreversibile.</DrawerDescription>
					</DrawerHeader>
					<DrawerFooter>
						<Button variant={'secondary'} onClick={deleteDocumentModal.closeModal}>
							Annulla
						</Button>
						<Button variant={'destructive'} onClick={() => void deleteSelectedDocument(document._id)}>
							Elimina
						</Button>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
};
