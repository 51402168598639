import { Product, ProductPass } from '../types';

export const productsMock: Product[] = [
	{
		name: 'Abbonamento Ginnastica Posturale Mensile 2v',
		color: 'yellow',
		description: 'Abbonamento Ginnastica Posturale Mensile da 2 volte a settimana',
		configuration: {
			time: {
				type: 'TIME',
				variant: 'DURATION',
				quantity: 1,
				timePeriod: 'MONTHS',
			},
			payment: {
				reasonId: '657d94b70d00f0e472df80a0',
				amount: 55,
			},
		},
		id: '40965acc-4ec7-4045-b79f-cc75f2086a36',
	},
	{
		name: 'Abbonamento Fitness Trimestrale 3v',
		color: 'lime',
		description: 'Abbonamento Fitness Trimestrale da 3 volte a settimana',
		configuration: {
			time: {
				type: 'TIME',
				variant: 'DURATION_WITH_A_PERIOD',
				startDate: '2024-12-01T00:00:00.000Z',
				expirationDate: '2025-01-01T00:00:00.000Z',
			},
			payment: {
				reasonId: '657d910661818b6d2afef49c',
				amount: 145,
			},
		},
		id: '7539b9a7-dd87-40e2-88e9-cd41195f0002',
	},
	{
		name: 'Tesseramento annuale Master',
		color: 'purple',
		description:
			"La quota di rinnovo tesserato Fidal comprende: tesseramento annuale Fidal e relativa assicurazione infortuni, utilizzo pista e spogliatoio di atletica di Via Toti secondo orario e calendario comunale. La pratica sportiva agonistica è consentita solo se in possesso di certificato medico rilasciato da un centro medico sportivo autorizzato e l'utilizzo della pista è subordinato alla regolare validità di detto certificato agonistico.",
		configuration: {
			time: {
				type: 'TIME',
				variant: 'DURATION',
				quantity: 12,
				timePeriod: 'MONTHS',
			},
		},
		id: '00e0561e-4b2d-40a0-a471-c74738cbc621',
	},
	{
		name: 'BABY E CUCCIOLI',
		color: 'cyan',
		description:
			'Non è stato previsto un pacchetto per i baby/cuccioli in quanto la partecipazione agli allenamenti non è solitamente intensa.\nLa tariffa applicata è a partecipazione con una presenza minima di 3 giornate. \n',
		configuration: {
			time: {
				type: 'TIME',
				variant: 'DURATION_TILL_EXPIRATION_DATE',
				expirationDate: '2025-03-30T00:00:00.000Z',
			},
		},
		id: '58a73925-6d35-4455-80ac-8c0d08d613be',
	},
];

export const passesMock: ProductPass[] = [
	{
		id: 'db11110a-456c-4faf-8364-f0f55f3fe2e7',
		activatedDate: '2024-12-04T00:00:00.000Z',
		expirationDate: '2024-12-31T00:00:00.000Z',
		operation: {
			operationId: '',
			amount: 55,
			cashed: true,
			cashedDate: '2024-12-03T00:00:00.000Z',
			expectedCashedDate: undefined,
			reasonId: '657d94b70d00f0e472df80a0',
		},
		operationId: '67505e066326eefa908bc32a',
		orgPerson: {
			name: 'Cecilia',
			surname: 'Marangoni',
			sex: 'F',
		},
		orgPersonId: '9e08810f-39a7-4c4f-b249-a53123fd8bd3',
		product: {
			name: 'Abbonamento Ginnastica Posturale Mensile 2v',
			description: 'Abbonamento Ginnastica Posturale Mensile da 2 volte a settimana',
			color: 'yellow',
			configuration: {
				time: {
					type: 'TIME',
					variant: 'DURATION',
					quantity: 1,
					timePeriod: 'MONTHS',
				},
				payment: {
					reasonId: '657d94b70d00f0e472df80a0',
					amount: 55,
				},
			},
		},
		productId: '40965acc-4ec7-4045-b79f-cc75f2086a36',
		startDate: '2024-12-01T00:00:00.000Z',
		state: 'ACTIVE',
	},
	{
		id: 'db11110a-456c-4faf-8361-f0f55f3fe2e7',
		activatedDate: '2024-12-04T00:00:00.000Z',
		expirationDate: '2024-12-31T00:00:00.000Z',
		operation: {
			operationId: '',
			amount: 55,
			cashed: false,
			cashedDate: undefined,
			expectedCashedDate: undefined,
			reasonId: '657d94b70d00f0e472df80a0',
		},
		operationId: '67505e066326eefa908bc32a',
		orgPerson: {
			name: 'Cecilia',
			surname: 'Marangoni',
			sex: 'F',
		},
		orgPersonId: '9e08810f-39a7-4c4f-b249-a53123fd8bd3',
		product: {
			name: 'Abbonamento Buzz',
			description: 'Abbonamento Ginnastica Posturale Mensile da 2 volte a settimana',
			color: 'pink',
			configuration: {
				time: {
					type: 'TIME',
					variant: 'DURATION',
					quantity: 1,
					timePeriod: 'MONTHS',
				},
				payment: {
					reasonId: '657d94b70d00f0e472df80a0',
					amount: 55,
				},
			},
		},
		productId: '40965acc-4ec7-4045-b79f-cc75f2086a36',
		startDate: '2024-12-01T00:00:00.000Z',
		state: 'ACTIVE',
	},
];
