import { Skeleton } from '@/components/skeleton';
import { useApi } from '../../../../hooks/use-api';
import { getAvailableProducts } from '../api';
import { Product } from '../types';
import { ProductCard } from './product-card';

type ProductListProps = {
	onProductClick?: () => void;
};

export const ProductList = (props: ProductListProps) => {
	const { data: products = [], isLoading } = useApi<Product[]>(getAvailableProducts);

	if (isLoading) {
		return (
			<div className="flex flex-col gap-4">
				<Skeleton className="h-24" />
				<Skeleton className="h-24" />
				<Skeleton className="h-24" />
			</div>
		);
	}

	if (!products.length) {
		return <p className="mt-4 text-center text-muted-foreground">Nessun pacchetto disponibile</p>;
	}

	return (
		<div className="flex flex-col gap-4">
			{products.map(product => (
				<ProductCard product={product} key={product.id} onClick={props.onProductClick} />
			))}
		</div>
	);
};
