import { formatDate } from '@/utils';
import { Product } from '../types';

export const ProductConfigurationRenderer = ({ product }: { product: Product }) => {
	const { variant } = product.configuration.time;

	if (variant === 'DURATION') {
		const { timePeriod, quantity } = product.configuration.time;

		return (
			<span>
				Valido per{' '}
				<strong>
					{quantity}{' '}
					{timePeriod === 'DAYS'
						? `giorn${quantity !== 1 ? 'i' : 'o'}`
						: timePeriod === 'WEEKS'
							? `settiman${quantity !== 1 ? 'a' : 'e'}`
							: `mes${quantity !== 1 ? 'i' : 'e'}`}
				</strong>
			</span>
		);
	}

	if (variant === 'DURATION_WITH_A_PERIOD') {
		const { startDate, expirationDate } = product.configuration.time;

		return (
			<span>
				Valido dal <strong className="capitalize">{formatDate(startDate)}</strong> al{' '}
				<strong className="capitalize">{formatDate(expirationDate)}</strong>
			</span>
		);
	}

	if (variant === 'DURATION_TILL_EXPIRATION_DATE') {
		const { expirationDate } = product.configuration.time;

		return (
			<span>
				Valido fino al <strong className="capitalize">{formatDate(expirationDate)}</strong>
			</span>
		);
	}

	return <></>;
};
