import { Card } from '@/components/card';
import { Spinner } from '@/components/spinner';
import { useEffect, useState } from 'react';
import { DocumentModel, getDocuments } from '../../../../api/documents/documents';
import { EmptyList } from '../../../../components';
import { DocumentCard } from './document-card';
import { UploadOrRequestDocument, UploadOrRequestDocumentProps } from './upload/upload-or-request-document';

type DocumentListProps = {
	typeEntity: `org_person` | `organization`;
	refEntity: string;
} & Pick<UploadOrRequestDocumentProps, 'allowUpload' | 'allowRequest'>;

export const DocumentList = (props: DocumentListProps) => {
	const [docs, setDocs] = useState<DocumentModel[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const fetchDocuments = async () => {
		setIsLoading(true);
		try {
			const resp = await getDocuments(props.typeEntity, props.refEntity);
			setDocs(resp.data);
		} catch (e) {
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		void fetchDocuments();
	}, []);

	const canUploadOrRequest = (props.allowUpload || props.allowRequest) && props.typeEntity === 'org_person';

	return (
		<div>
			{canUploadOrRequest && (
				<UploadOrRequestDocument allowRequest={props.allowRequest} allowUpload={props.allowUpload} />
			)}
			<Card className="border-slate-100">
				{isLoading ? (
					<div className="my-4 flex justify-center">
						<Spinner />
					</div>
				) : (
					<>
						{!docs.length ? (
							<EmptyList />
						) : (
							<div className={'flex flex-col'}>
								{docs.map(document => (
									<DocumentCard document={document} key={document._id} onDelete={fetchDocuments} />
								))}
							</div>
						)}
					</>
				)}
			</Card>
		</div>
	);
};
