import { I18nProvider } from '@gle/providers.i18n';
import { Settings } from 'luxon';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './router/app-router';

Settings.defaultLocale = 'it';

const App = () => {
	return (
		<I18nProvider>
			<BrowserRouter>
				<AppRouter />
			</BrowserRouter>
		</I18nProvider>
	);
};

export default App;
