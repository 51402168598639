import { Text } from '@gle/base-ui.typography.text';
import { HTMLAttributes } from 'react';

export const Divider: React.FC<Omit<HTMLAttributes<HTMLDivElement>, 'children'>> = ({ style, ...props }) => {
	return (
		<div className={'flex items-center'} style={{ height: 40 }}>
			<div className={'flex-grow'} style={{ borderTop: '1px solid #eee', ...style }} {...props} />
			{props.title && (
				<Text className={'pl-2 pr-2'} size={'m'} color={'gray'}>
					{props.title}
				</Text>
			)}
			<div className={'flex-grow'} style={{ borderTop: '1px solid #eee', ...style }} {...props} />
		</div>
	);
};
