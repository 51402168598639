import { cn } from '@/lib/utils';
import {
	ArchiveIcon,
	BellIcon,
	CalendarIcon,
	ClipboardCheckIcon,
	CreditCardIcon,
	EuroIcon,
	HomeIcon,
	LogOutIcon,
	MenuIcon,
	RssIcon,
	SettingsIcon,
	TagsIcon,
	UserIcon,
	UsersIcon,
} from 'lucide-react';
import { ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { useProfiles } from '../../context/profiles/use-profiles';
import { useReactNative } from '../../hooks/use-react-native';
import { PermissionV2, PermissionWrapper } from '../permission-wrapper/permission-wrapper';

enum EMenuSection {
	'HOME' = 'Home',
	'PROFILE' = 'Profilo',
	'PAYMENTS' = 'Pagamenti',
	'PRODUCTS' = 'Pacchetti',
	'DOCUMENTS' = 'Documenti',
	'PROFILES' = 'Scelta Profilo',
	'CALENDAR' = 'Calendario',
	'POSTS' = 'Bacheca',
	'AGREEMENTS' = 'Consensi',
	'NOTIFICATIONS' = 'Notifiche',
	'MEMBERSHIP_CARD' = 'Tessera',
}

const menu: MenuItem[] = [
	{ title: EMenuSection.HOME, path: 'home', icon: <HomeIcon size={20} /> },
	{ title: EMenuSection.PROFILE, path: 'profile', icon: <UserIcon size={20} /> },
	{ title: EMenuSection.PAYMENTS, path: 'payments', icon: <EuroIcon size={20} /> },
	{
		title: EMenuSection.PRODUCTS,
		path: 'products',
		icon: <TagsIcon size={20} />,
		// TODO: permission: '???,
	},
	{ title: EMenuSection.DOCUMENTS, path: 'documents', icon: <ArchiveIcon size={20} /> },
	{ title: EMenuSection.CALENDAR, path: 'calendar', icon: <CalendarIcon size={20} /> },
	{ title: EMenuSection.POSTS, path: 'posts', icon: <RssIcon size={20} />, permission: 'feed:read' },
	{
		title: EMenuSection.AGREEMENTS,
		path: 'agreements',
		icon: <ClipboardCheckIcon size={20} />,
		permission: 'agreements:read',
	},
	{
		title: EMenuSection.MEMBERSHIP_CARD,
		path: 'membership-card',
		icon: <CreditCardIcon size={20} />,
	},
	{
		title: EMenuSection.NOTIFICATIONS,
		path: 'notifications',
		icon: <BellIcon size={20} />,
	},
];

export type MenuItem = {
	title: string;
	path: string;
	icon: ReactNode;
	permission?: PermissionV2;
};

export const Menu = () => {
	const [isOpen, setIsOpen] = useState<boolean | undefined>();

	const onToggle = () => {
		if (isOpen) {
			onClose();
		} else {
			onOpen();
		}
	};

	const onOpen = () => {
		document.body.classList.add('stop-scrolling');
		setIsOpen(true);
	};

	const onClose = () => {
		document.body.classList.remove('stop-scrolling');
		setIsOpen(false);
	};

	return (
		<div>
			<MenuIcon onClick={onToggle} size={24} className="cursor-pointer lg:hidden" />
			<MenuPage isOpen={isOpen} onItemSelected={onClose} />
		</div>
	);
};

export const MenuPage = (props: { isOpen?: boolean; onItemSelected: () => void }) => {
	const { logout } = useAuth();
	const { unselectProfile, profiles } = useProfiles();
	const { sendMessageToRN, isFromApp, isAppVersionGte } = useReactNative();

	return (
		<div
			className={cn(
				'absolute top-16 z-[1] flex h-screen w-full flex-col overflow-y-auto bg-white pb-20 transition-all duration-300 lg:left-0 lg:top-20 lg:h-auto lg:w-auto lg:rounded-br-lg lg:rounded-tr-lg lg:border lg:border-solid lg:border-slate-100 lg:pb-0 lg:shadow-sm',
				{
					'-left-full': !props.isOpen,
					'left-0': props.isOpen,
				}
			)}>
			<div className={'flex flex-col p-5'}>
				{menu.map(item => (
					<PermissionWrapper permission={item.permission as PermissionV2} key={item.title}>
						<NavLink
							onClick={() => {
								props.onItemSelected();
							}}
							key={item.title}
							to={`/${item.path}`}
							className={({ isActive }) =>
								cn(
									'mb-3 flex cursor-pointer items-center gap-3 rounded-md px-2 py-2 text-slate-600 hover:bg-slate-100',
									{
										'font-semibold text-slate-900': isActive,
									}
								)
							}>
							<div className={'w-5'}>{item.icon}</div>
							<p>{item.title}</p>
						</NavLink>
					</PermissionWrapper>
				))}
			</div>
			<div className={'border-t border-solid border-slate-100 p-5'}>
				{profiles && (
					<div
						className={cn(
							'mb-3 flex cursor-pointer items-center gap-3 rounded-md px-2 py-2 text-slate-600 hover:bg-slate-100'
						)}
						onClick={() => {
							props.onItemSelected();
							unselectProfile();
						}}>
						<UsersIcon size={20} />
						<p>Profili</p>
					</div>
				)}
				<div
					className={cn(
						'mb-3 flex cursor-pointer items-center gap-3 rounded-md px-2 py-2 text-slate-600 hover:bg-slate-100'
					)}
					onClick={() => {
						props.onItemSelected();

						if (isFromApp && isAppVersionGte('1.2.2')) {
							void sendMessageToRN('request-open-account-settings', {});
						} else {
							window.location.href = `${window._env_.REACT_APP_AUTH_APP_URL}settings`;
						}
					}}>
					<SettingsIcon size={20} />
					<p>Impostazioni</p>
				</div>

				<div
					className={cn(
						'mb-3 flex cursor-pointer items-center gap-3 rounded-md px-2 py-2 text-red-600 hover:bg-red-100'
					)}
					onClick={logout}>
					<LogOutIcon size={20} />
					<p className="font-medium">Logout</p>
				</div>
			</div>
		</div>
	);
};
