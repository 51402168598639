import { Button } from '@/components/button';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '@/components/drawer';
import { useModal } from '../../../../../hooks/use-modal';
import { Product } from '../../types';
import { ProductPassActivationForm } from './product-pass-activation-form';

type ProductPassActivationButtonProps = {
	product: Product;
	onPassCreated?: () => void;
};

export const ProductPassActivationButton = (props: ProductPassActivationButtonProps) => {
	const modal = useModal();

	return (
		<>
			<Button size="lg" onClick={modal.openModal}>
				Attiva pacchetto
			</Button>
			<Drawer
				open={modal.isModalVisible}
				onClose={modal.closeModal}
				onOpenChange={status => {
					if (!status) modal.closeModal();
				}}>
				<DrawerContent>
					<DrawerHeader>
						<DrawerTitle>Conferma attivazione</DrawerTitle>
						<DrawerDescription>
							Conferma per attivare il pacchetto nelle seguenti date.
							{props.product.configuration.payment &&
								` Potrai procedere con il pagamento dopo aver confermato l'attivazione.`}
						</DrawerDescription>
					</DrawerHeader>

					<div className="p-4">
						<ProductPassActivationForm
							product={props.product}
							onCancel={modal.closeModal}
							onComplete={() => {
								modal.closeModal();
								props.onPassCreated?.();
							}}
						/>
					</div>
				</DrawerContent>
			</Drawer>
		</>
	);
};
