import { formatDate } from '@/utils';
import { Organization, OrgPerson } from '../../../../context/org-person';
import { CompleteMembershipCard, MembershipCardSettings } from '../types';

export const createMembershipCardData = (
	payload: MembershipCardSettings,
	data: {
		organization: Organization;
		season: string;
		orgPerson: OrgPerson;
	}
) => {
	return {
		organization: mapOrganizationFields(payload.organization, data.organization, data.season),
		member: mapMemberFields(payload.member, data.orgPerson),
	};
};

const mapOrganizationFields = (
	payload: MembershipCardSettings['organization'],
	organization: Organization,
	season: string
): CompleteMembershipCard['organization'] => {
	return {
		name: {
			key: 'name',
			value: organization.name || '',
			label: 'Nome club',
			enabled: payload.name,
		},
		logo: {
			key: 'logo',
			value: organization.logo_url || '',
			label: 'Logo club',
			enabled: payload.logo,
		},
		season: {
			key: 'season',
			value: season,
			label: 'Stagione',
			enabled: payload.season,
		},
		primaryColor: {
			key: 'primaryColor',
			value: organization.colors?.[1],
			label: 'Colore primario',
			enabled: payload.primaryColor,
		},
		secondaryColor: {
			key: 'secondaryColor',
			value: organization.colors?.[2],
			label: 'Colore secondario',
			enabled: payload.secondaryColor,
		},
		tertiaryColor: {
			key: 'tertiaryColor',
			value: organization.colors?.[3],
			label: 'Colore terziario',
			enabled: payload.tertiaryColor,
		},
	};
};

const mapMemberFields = (
	member: MembershipCardSettings['member'],
	orgPerson: OrgPerson
): CompleteMembershipCard['member'] => {
	const orgPersonGroups =
		orgPerson.groups
			?.filter(group => group.groupType !== 'financial')
			.filter((group, index, self) => index === self.findIndex(g => g.groupId === group.groupId))
			.map(group => group.groupName)
			.join(', ') || '';

	const registrationNumber =
		orgPerson.metadata.athlete?.technicalProfile?.registrationNumber ||
		orgPerson.metadata.managerialStaff?.registrationNumber ||
		orgPerson.metadata.technicalStaff?.registrationNumber;

	return {
		denomination: {
			key: 'denomination',
			value: `${orgPerson.name} ${orgPerson.surname}`,
			label: 'Nome e cognome',
			enabled: member.denomination,
		},
		fiscalCode: {
			key: 'fiscalCode',
			value: orgPerson.fiscalCode,
			label: 'Codice fiscale',
			enabled: member.fiscalCode,
		},
		groups: {
			key: 'groups',
			value: orgPersonGroups,
			label: 'Gruppo',
			enabled: member.groups,
		},
		photo: {
			key: 'photo',
			value: orgPerson.personMetadata.avatar || '',
			label: 'Foto',
			enabled: member.photo,
		},
		medicalExamination: {
			key: 'medicalExamination',
			value: orgPerson.personMetadata.dates?.medicalExaminationExpirationDate
				? formatDate(orgPerson.personMetadata.dates.medicalExaminationExpirationDate)
				: '',
			label: 'Visita medica',
			enabled: member.medicalExamination,
		},
		registrationNumber: {
			key: 'registrationNumber',
			value: registrationNumber,
			label: 'Matricola / Tessera',
			enabled: member.registrationNumber,
		},
		birthdate: {
			key: 'birthdate',
			value: orgPerson.personMetadata.dates?.birthdate
				? formatDate(orgPerson.personMetadata.dates.birthdate)
				: '',
			label: 'Data di nascita',
			enabled: member.birthdate,
		},
	};
};
