import { Button } from '@/components/button';
import { Card, CardContent } from '@/components/card';
import { TailwindColorRenderer } from '@/components/tailwind-color-renderer';
import { cn } from '@/lib/utils';
import { formatDate } from '@/utils';
import { ChevronRightIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Currency } from '../../../../components';
import { ProductPass } from '../types';
import { getProductPassStatus } from '../utils/get-product-pass-status';
import { ProductPassPaymentStatusBadge } from './product-pass-payment-status-badge';
import { ProductPassProgressBar } from './product-pass-progress-bar';
import { ProductPassStatusBadge } from './product-pass-status-badge';

type ProductPassCardProps = {
	pass: ProductPass;
	className?: string;
	onClick?: () => void;
};

export const ProductPassCard = (props: ProductPassCardProps) => {
	const passStatus = getProductPassStatus(props.pass.state, props.pass.startDate, props.pass.expirationDate);

	return (
		<Card
			onClick={props.onClick}
			className={cn('overflow-hidden', {
				'text-zinc-400': passStatus === 'expired',
			})}>
			<CardContent className="p-0">
				<div className="space-y-4 p-4">
					<div className="space-x-2">
						<ProductPassStatusBadge pass={props.pass} />
						{props.pass.operation && <ProductPassPaymentStatusBadge payment={props.pass.operation} />}
					</div>

					<div className="space-y-1">
						<TailwindColorRenderer color={props.pass.product.color} className="h-3 w-10 rounded-2xl" />
						<p className="text-xl font-bold tracking-tighter">{props.pass.product.name}</p>
						{/* <p className="text-sm text-muted-foreground">{props.pass.product.description}</p> */}
					</div>

					<div className="flex justify-between">
						<div className="space-y-0.5">
							<p className="text-sm text-muted-foreground">Data di inizio</p>
							<p className="font-medium">{formatDate(props.pass.startDate)}</p>
						</div>
						<div className="space-y-0.5">
							<p className="text-sm text-muted-foreground">Data di fine</p>
							<p className="font-medium">{formatDate(props.pass.expirationDate)}</p>
						</div>
					</div>

					<ProductPassProgressBar pass={props.pass} />

					<div className="flex items-center justify-between">
						<div className="text-xl font-bold tracking-tighter">
							{props.pass.operation ? <Currency value={props.pass.operation.amount} /> : <>Gratuito</>}
						</div>

						<Button
							asChild
							variant="secondary"
							onClick={() => {
								props.onClick?.();
							}}>
							<Link to={`/products/pass/${props.pass.id}`}>
								Dettagli <ChevronRightIcon size={18} />
							</Link>
						</Button>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};
