import { Skeleton } from '@/components/skeleton';
import { useSeason } from '../../../../context/season';
import { useApi } from '../../../../hooks/use-api';
import { getMyPasses } from '../api';
import { ProductPass } from '../types';
import { ProductPassCard } from './product-pass-card';

type ProductPassListProps = {
	onPassClick?: () => void;
};

export const ProductPassList = (props: ProductPassListProps) => {
	const { currentSeason } = useSeason();
	const { data: passes = [], isLoading } = useApi<ProductPass[]>(getMyPasses, { season: currentSeason });

	if (isLoading) {
		return (
			<div className="flex flex-col gap-4">
				<Skeleton className="h-24" />
				<Skeleton className="h-24" />
				<Skeleton className="h-24" />
			</div>
		);
	}

	if (!passes.length) {
		return <p className="mt-4 text-center text-muted-foreground">Nessun pacchetto attivato</p>;
	}

	return (
		<div className="flex flex-col gap-4">
			{passes.map(pass => (
				<ProductPassCard pass={pass} key={pass.id} onClick={props.onPassClick} />
			))}
		</div>
	);
};
