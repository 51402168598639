import { Badge } from '@/components/badge';
import { DateTime } from 'luxon';
import { ProductPassPayment } from '../types';

type ProductPassPaymentStatusBadgeProps = {
	payment: ProductPassPayment;
};

export const ProductPassPaymentStatusBadge = (props: ProductPassPaymentStatusBadgeProps) => {
	const paymentExpectedDatePassed = props.payment.expectedCashedDate
		? DateTime.fromISO(props.payment.expectedCashedDate) < DateTime.now()
		: true;

	return (
		<Badge variant={props.payment.cashed ? 'success' : paymentExpectedDatePassed ? 'destructive' : 'warning'}>
			{props.payment.cashed ? 'Pagato' : 'Da pagare'}
		</Badge>
	);
};
