import { Button } from '@/components/button';
import { Card, CardContent } from '@/components/card';
import { TailwindColorRenderer } from '@/components/tailwind-color-renderer';
import { CalendarDaysIcon, ChevronRightIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Currency } from '../../../../components';
import { Product } from '../types';
import { isProductAvailable } from '../utils/is-product-available';
import { ProductConfigurationRenderer } from './product-configuration-renderer';

type ProductCardProps = {
	product: Product;
	onClick?: () => void;
};

export const ProductCard = (props: ProductCardProps) => {
	const isAvailable = isProductAvailable(props.product);

	return (
		<Card onClick={props.onClick}>
			<CardContent className="p-0">
				<div className="space-y-4 p-4">
					<div className="space-y-1">
						<TailwindColorRenderer color={props.product.color} className="h-3 w-10 rounded-2xl" />
						<p className="text-xl font-bold tracking-tighter">{props.product.name}</p>
						<p className="line-clamp-4 text-sm text-muted-foreground">{props.product.description}</p>
					</div>

					<div className="flex items-center gap-1.5 border-y border-solid border-muted py-2 text-sm">
						<CalendarDaysIcon className="h-4 w-4 text-muted-foreground" />
						<p>
							<ProductConfigurationRenderer product={props.product} />
						</p>
					</div>

					<div className="flex items-center justify-between">
						<p className="text-xl font-bold tracking-tighter">
							{props.product.configuration.payment?.amount ? (
								<Currency value={props.product.configuration.payment?.amount} />
							) : (
								<>Gratuito</>
							)}
						</p>

						{isAvailable ? (
							<Button
								asChild
								variant="secondary"
								onClick={() => {
									props.onClick?.();
								}}>
								<Link to={`/products/${props.product.id}`}>
									Scopri di più <ChevronRightIcon size={18} />
								</Link>
							</Button>
						) : (
							<Button disabled variant="secondary">
								Pacchetto scaduto
							</Button>
						)}
					</div>
				</div>
			</CardContent>
		</Card>
	);
};
