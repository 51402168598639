import { Badge, BadgeProps } from '@/components/badge';
import { ProductPass } from '../types';
import { getProductPassStatus } from '../utils/get-product-pass-status';

const statusMap: {
	[key: string]: {
		label: string;
		variant: BadgeProps['variant'];
		tooltip?: string | ((remainingDays: number) => string);
	};
} = {
	active: { label: 'In corso', variant: 'success' },
	'not-started': {
		label: 'Da iniziare',
		variant: 'secondary',
		tooltip: 'La data di inizio è futura rispetto ad oggi',
	},
	expiring: {
		label: 'In scadenza',
		variant: 'warning',
		tooltip: (remainingDays: number) => `Rimangono ${remainingDays} giorni alla scadenza`,
	},
	expired: { label: 'Terminato', variant: 'secondary', tooltip: 'La data di scadenza è passata' },
	inactive: {
		label: 'Non attivo',
		variant: 'secondary',
		tooltip: 'Questo elemento è stato disattivato',
	},
};

type ProductPassStatusBadgeProps = { pass: ProductPass };

export const ProductPassStatusBadge = (props: ProductPassStatusBadgeProps) => {
	const {
		pass: { state, expirationDate, startDate },
	} = props;

	const passStatus = getProductPassStatus(state, startDate, expirationDate);
	const currentStatus = statusMap[passStatus];

	return <Badge variant={currentStatus.variant}>{currentStatus.label}</Badge>;
};
