import { TailwindColor } from '@/lib/colors';
import { cn } from '@/lib/utils';

export const TailwindColorRenderer = (props: { color: TailwindColor; className?: string }) => {
	return (
		<span
			className={cn(
				'block h-5 w-5 rounded border border-solid border-muted',
				`bg-${props.color}-500`,
				props.className
			)}></span>
	);
};
