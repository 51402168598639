import { Skeleton } from '@/components/skeleton';
import { PeriodFilter } from '../../../../../components/period-filter';
import { useCalendarEvents } from '../../hooks';
import { EventCard } from './event-card';

type EventListProps = { orgPersonId: string; organizationId: string };

export const EventList = (props: EventListProps) => {
	const { events, start, end, onPeriodChange, isLoading, rangeType } = useCalendarEvents(
		props.orgPersonId,
		props.organizationId
	);

	return (
		<div>
			<div className="mb-4 py-1">
				<PeriodFilter
					from={start}
					to={end}
					onChange={onPeriodChange}
					allowedRanges={['week', 'month']}
					rangeType={rangeType}
				/>
			</div>

			<div className="flex flex-col gap-4">
				{isLoading ? (
					<EventListSkeleton />
				) : (
					<>
						{!events.length ? (
							<p className="mt-4 text-center text-gray-500">
								Nessun evento in programma per il periodo selezionato
							</p>
						) : (
							<>
								{events.map(event => (
									<EventCard event={event} key={event._id} orgPersonId={props.orgPersonId} />
								))}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

const EventListSkeleton = () => {
	return (
		<div className="flex flex-col gap-4">
			<Skeleton className="h-24" />
			<Skeleton className="h-24" />
			<Skeleton className="h-24" />
		</div>
	);
};
