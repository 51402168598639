import { Heading } from '@/components/heading';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/tabs';
import { useTabChange } from '../../../../hooks/use-tab-change';
import { ProductList } from '../components/product-list';
import { ProductPassList } from '../components/product-pass-list';

export const ProductsPage = () => {
	const { activeKey, handleTabChange } = useTabChange('mine');

	return (
		<div className="pb-4">
			<Heading className="mb-4">Pacchetti</Heading>

			<Tabs defaultValue={activeKey} onValueChange={handleTabChange}>
				<TabsList className="grid w-full grid-cols-2">
					<TabsTrigger value="mine">I miei pacchetti</TabsTrigger>
					<TabsTrigger value="new">Attiva nuovo</TabsTrigger>
				</TabsList>
				<TabsContent value="mine">
					<ProductPassList />
				</TabsContent>
				<TabsContent value="new">
					<ProductList />
				</TabsContent>
			</Tabs>
		</div>
	);
};
