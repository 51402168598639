import { DateTime } from 'luxon';
import { ProductPass } from '../types';

export const getProductPassStatus = (
	state: ProductPass['state'],
	startDate: string,
	expirationDate: string
): 'active' | 'not-started' | 'expiring' | 'expired' | 'inactive' => {
	const now = DateTime.now();

	const start = DateTime.fromISO(startDate).startOf('day');
	const expiration = DateTime.fromISO(expirationDate).startOf('day');

	if (state === 'INACTIVE') {
		return 'inactive';
	}

	if (start > now.endOf('day')) {
		return 'not-started';
	}

	if (expiration < now.startOf('day')) {
		return 'expired';
	}

	const remainingDays = expiration.diff(now, 'days').days;

	if (remainingDays <= 7) {
		return 'expiring';
	}

	return 'active';
};
