import { Button } from '@/components/button';
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	EyeOffIcon,
	RotateCcwIcon,
	ViewIcon,
	ZoomInIcon,
	ZoomOutIcon,
} from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { ReactZoomPanPinchState, TransformComponent, TransformWrapper, useControls } from 'react-zoom-pan-pinch';
import { v4 as uuid } from 'uuid';
import Image from './image-in-pdf';
import { PDFPage } from './pdf-page';
import { AddSignatureButton } from './signature/add-signature-button';
import { ConfirmSignatureButton } from './signature/confirm-signature-button';
import { readAsDataURL, readAsImage, readPDFfromURL } from './utils/async-reader';
import { saveAsBase64 } from './utils/pdf';
import { getAsset } from './utils/prepare-assets';

getAsset('pdfjsLib');

type PdfEditorProps = {
	url: string;
	allowSignature?: boolean;
	isSigning?: boolean;
	isSavingSignedDocument?: boolean;
	onSignStarted?: () => void;
	onSignCanceled?: () => void;
	onSignCompleted?: (base64: string) => void;
};

export const PdfEditor = (props: PdfEditorProps) => {
	const [pdfFile, setPdfFile] = useState<File | null>(null);
	const [pdfName, setPdfName] = useState<string>('');

	const [pages, setPages] = useState([]);

	const [pagesScale, setPagesScale] = useState<number[]>([]);
	const [allObjects, setAllObjects] = useState<any[]>([]);

	const [selectedPageIndex, setSelectedPageIndex] = useState(-1);

	const addPDF = async (url: string) => {
		try {
			const { pdf, file: _pdfFile } = await readPDFfromURL(url);

			const numPages = pdf.numPages;
			const pdfPages = Array.from({ length: numPages }, (_, i) => pdf.getPage(i + 1));

			setPages(pdfPages as any);

			setPdfFile(_pdfFile);
			setPdfName(_pdfFile.name);

			setAllObjects(Array(numPages).fill([]) as any);
			setPagesScale(Array(numPages).fill(1) as any);
			setSelectedPageIndex(0);
		} catch (e) {
			console.log('Failed to add pdf.');
			throw e;
		}
	};

	const addSignature = async (file: File) => {
		try {
			const base64 = await readAsDataURL(file);
			const img = await readAsImage(base64 as string);
			const id = uuid();

			const { width, height } = img;

			const object = {
				id,
				type: 'image',
				width: width,
				height: height,
				x: 5,
				y: 5,
				payload: img,
				file,
			};

			setAllObjects(
				prevObjects =>
					prevObjects.map((objects, pIndex) =>
						pIndex === selectedPageIndex ? [...objects, object] : objects
					) as any
			);

			props.onSignStarted?.();
		} catch (e) {
			console.log('Fail to add image.', e);
		}
	};

	const goToPreviousPage = () => {
		if (selectedPageIndex > 0) {
			const nextIndex = selectedPageIndex - 1;

			setSelectedPageIndex(nextIndex);
		}
	};

	const goToNextPage = () => {
		if (selectedPageIndex < pages.length - 1) {
			const nextIndex = selectedPageIndex + 1;

			setSelectedPageIndex(nextIndex);
		}
	};

	const updateObject = (
		objectId: string,
		payload: { width?: number; height?: number; x?: number; y?: number; file?: File; alreadyInitialized?: boolean }
	) => {
		setAllObjects(
			prevObjects =>
				prevObjects.map((objects, pIndex) =>
					pIndex === selectedPageIndex
						? (objects as any).map((object: any) =>
								object.id === objectId ? { ...object, ...payload } : object
							)
						: objects
				) as any
		);
	};

	const deleteObject = (objectId: string) => {
		setAllObjects(
			prevObjects =>
				prevObjects.map((objects, pIndex) =>
					pIndex === selectedPageIndex
						? (objects as any).filter((object: any) => object.id !== objectId)
						: objects
				) as any
		);
	};

	const onMeasure = (scale: number, i: number) => {
		const _ = [...pagesScale];
		_[i] = scale;
		setPagesScale(_);
	};

	const saveSignedPDF = async () => {
		if (!pdfFile || !!props.isSavingSignedDocument || !pages.length) return;

		try {
			const base64 = await saveAsBase64(pdfFile, allObjects);
			props.onSignCompleted?.(base64);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		addPDF(props.url);
	}, []);

	const canSaveDocument = selectedPageIndex === pages.length - 1 && allObjects.filter(o => o.length > 0).length > 0;

	return (
		<div>
			<div className="mx-auto flex max-w-lg flex-col items-center">
				{pages.length > 0 && selectedPageIndex >= 0 ? (
					<>
						{props.allowSignature && (
							<div className="mb-2 flex w-full justify-between">
								<AddSignatureButton
									label={`Aggiungi firma ${pages.length > 1 ? `pag. ${selectedPageIndex + 1}` : ''}`}
									disabled={!pdfFile || !!props.isSavingSignedDocument}
									onSignCanceled={props.onSignCanceled}
									onSignAdded={file => addSignature(file)}
								/>

								<ConfirmSignatureButton
									disabled={!canSaveDocument}
									isLoading={!!props.isSavingSignedDocument}
									onConfirm={() => saveSignedPDF()}
								/>
							</div>
						)}

						{pages.map((page, pIndex) => (
							<div
								key={pIndex}
								className="w-full"
								style={{ display: pIndex === selectedPageIndex ? 'block' : 'none' }}>
								<SinglePage
									page={page}
									pIndex={pIndex}
									selectedPageIndex={selectedPageIndex}
									onMeasure={scale => onMeasure(scale, pIndex)}
									allObjects={allObjects}
									pagesScale={pagesScale}
									updateObject={updateObject}
									deleteObject={deleteObject}
									isSavingSignedDocument={props.isSavingSignedDocument}
									canSaveDocument={canSaveDocument}
								/>
							</div>
						))}

						{pages.length > 1 && (
							<div className="my-3 flex items-center justify-between gap-2">
								<Button
									onClick={goToPreviousPage}
									disabled={selectedPageIndex === 0}
									variant={'secondary'}>
									<ChevronLeftIcon size={16} />
								</Button>
								<p className="text-sm text-muted-foreground">
									Pagina {selectedPageIndex + 1} di {pages.length}
								</p>
								<Button
									onClick={goToNextPage}
									disabled={selectedPageIndex === pages.length - 1}
									variant={'secondary'}>
									<ChevronRightIcon size={16} />
								</Button>
							</div>
						)}
					</>
				) : (
					<p className="my-4 text-xs text-muted-foreground">Caricamento in corso...</p>
				)}
			</div>
		</div>
	);
};

const SinglePage = (props: {
	page: any;
	pIndex: number;
	selectedPageIndex: number;
	onMeasure: (scale: number, i: number) => void;
	allObjects: any[];
	pagesScale: number[];
	updateObject: (
		objectId: string,
		payload: { width?: number; height?: number; x?: number; y?: number; file?: File; alreadyInitialized?: boolean }
	) => void;
	deleteObject: (objectId: string) => void;
	isSavingSignedDocument?: boolean;
	onZoomScaleChange?: (zoomScale: number) => void;
	canSaveDocument?: boolean;
}) => {
	const [isPreview, setIsPreview] = useState<boolean>(false);
	const [zoomScale, setZoomScale] = useState<number>(1);

	const onZoomOrPan = (state: ReactZoomPanPinchState) => {
		setZoomScale(state.scale);
	};

	return (
		<div className="flex w-full flex-col items-center overflow-hidden border border-solid border-slate-200">
			<TransformWrapper
				pinch={{ disabled: true }}
				wheel={{
					disabled: true,
					step: 50,
					smoothStep: 0.01,
				}}
				onTransformed={({ state }) => onZoomOrPan(state)}>
				{({ zoomIn, zoomOut, resetTransform }) => (
					<>
						<PdfControls
							preview={{
								showToggle: !!props.canSaveDocument,
								onToggle: () => setIsPreview(prev => !prev),
								isEnabled: isPreview,
							}}
						/>
						<TransformComponent>
							<div className={`relative`}>
								{props.pIndex === props.selectedPageIndex && (
									<PDFPage
										key={props.pIndex}
										onMeasure={scale => props.onMeasure(scale, props.pIndex)}
										page={props.page}
									/>
								)}

								<div
									className="absolute left-0 top-0"
									style={{
										transform: `scale(${props.pagesScale[props.pIndex]})`,
										touchAction: 'none',
									}}>
									{(props.allObjects[props.pIndex] as any)?.map((object: any) => (
										<React.Fragment key={object.id}>
											{object.type === 'image' && (
												<Image
													onUpdate={e => props.updateObject(object.id, e)}
													onDelete={() => props.deleteObject(object.id)}
													file={object.file}
													payload={object.payload}
													x={object.x}
													y={object.y}
													width={object.width}
													height={object.height}
													pageScale={props.pagesScale[props.pIndex]}
													zoomScale={zoomScale}
													disableEdit={props.isSavingSignedDocument || isPreview}
												/>
											)}
										</React.Fragment>
									))}
								</div>
							</div>
						</TransformComponent>
					</>
				)}
			</TransformWrapper>

			<hr />
		</div>
	);
};

const PdfControls = (props: { preview: { onToggle: () => void; isEnabled: boolean; showToggle: boolean } }) => {
	const { zoomIn, zoomOut, resetTransform, instance } = useControls();

	return (
		<div className="flex w-full items-center justify-between bg-slate-100 p-1">
			<div className="flex w-full items-stretch gap-2">
				<button
					onClick={() => zoomIn()}
					className="rounded bg-slate-200 p-1 text-slate-600 active:bg-slate-400">
					<ZoomInIcon />
				</button>
				<button
					onClick={() => zoomOut()}
					className="rounded bg-slate-200 p-1 text-slate-600 active:bg-slate-400">
					<ZoomOutIcon />
				</button>
				{instance.transformState.scale !== 1 && (
					<button
						onClick={() => resetTransform()}
						className="rounded bg-slate-200 p-1 text-xs text-slate-600 active:bg-slate-400">
						<RotateCcwIcon />
					</button>
				)}
			</div>
			{props.preview.showToggle && (
				<button
					onClick={() => {
						if (props.preview.isEnabled) {
							resetTransform();
						}
						props.preview.onToggle();
					}}
					className="rounded bg-slate-200 p-1 text-xs text-slate-600 active:bg-slate-400">
					{props.preview.isEnabled ? <EyeOffIcon /> : <ViewIcon />}
				</button>
			)}
		</div>
	);
};
