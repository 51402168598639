import { Outlet } from 'react-router-dom';
import { useOrgPerson } from '../../../../context/org-person/use-org-person';
import { DocumentList } from './document-list';

export const PrivateDocumentList = () => {
	const { orgPerson } = useOrgPerson();

	return (
		<div className="mt-4">
			<DocumentList typeEntity={'org_person'} refEntity={orgPerson.orgPersonId} allowRequest allowUpload />
			<Outlet />
		</div>
	);
};
