import { apiClient } from '../../../../api/client';
import { MembershipCardSettings } from '../types';

export const getMembershipCardSettings = () => {
	return apiClient('club').get<MembershipCardSettings>(`settings/membership-card`);
};

export const setMembershipCardSettings = (payload: MembershipCardSettings) => {
	return apiClient('club').post<void>(`settings/membership-card`, payload);
};
