import { Button } from '@/components/button';
import { DatePicker } from '@/components/date-picker';
import { Label } from '@/components/label';
import { useToast } from '@/components/toast';
import { useConan } from '@golee/gle-conan-tracker';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useSeason } from '../../../../../context/season';
import { createPass, getCalculatedExpirationDate } from '../../api';
import { Product } from '../../types';

type ProductPassActivationFormProps = {
	product: Product;
	onComplete?: () => void;
	onCancel?: () => void;
};

// This is not cool but I don't find any better working solution for now.
const getDateWithoutTime = (startDate: Date) => {
	startDate.setHours(12, 0, 0, 0);
	const isoDate = DateTime.fromJSDate(startDate).toUTC().startOf('day').toISO();

	if (!isoDate) {
		throw new Error('Invalid start date');
	}

	return isoDate;
};

export const ProductPassActivationForm = (props: ProductPassActivationFormProps) => {
	const { toast } = useToast();
	const { trackEvent } = useConan();
	const { currentSeason } = useSeason();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [startDate, setStartDate] = useState<Date | null>();
	const [expirationDate, setExpirationDate] = useState<Date | null>();

	const canChangeStartDate = props.product.configuration.time.variant === 'DURATION';

	const initDates = async (startDate: string) => {
		try {
			const { data } = await getCalculatedExpirationDate(
				{
					configuration: props.product.configuration.time,
					startDate: getDateWithoutTime(DateTime.fromISO(startDate).toJSDate()),
				},
				{
					season: currentSeason,
				}
			);

			setStartDate(DateTime.fromISO(data.startDate).toJSDate());
			setExpirationDate(DateTime.fromISO(data.expirationDate).toJSDate());
		} catch (err) {}
	};

	const onStartDateChange = (value?: Date | null) => {
		if (!value) return;

		const isoDate = DateTime.fromJSDate(value).toISO();
		if (!isoDate) return;

		initDates(isoDate);
	};

	const onConfirm = async () => {
		setIsLoading(true);

		try {
			if (!startDate) {
				throw new Error('Invalid start date');
			}

			await createPass(
				{
					productId: props.product.id,
					startDate: getDateWithoutTime(startDate),
				},
				{
					season: currentSeason,
				}
			);

			trackEvent('family.financial.product-pass-created', {
				product: props.product,
				startDate: startDate.toISOString(),
			});

			toast({
				variant: 'success',
				title: 'Pacchetto attivato',
				description: 'Il pacchetto è stato attivato correttamente',
			});

			props.onComplete?.();
		} catch (err) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		initDates(DateTime.now().toISO());
	}, []);

	return (
		<div>
			<div className="space-y-4">
				<div className="space-y-2">
					<Label>Data di inizio</Label>
					<DatePicker
						value={startDate}
						onChange={onStartDateChange}
						disabled={!canChangeStartDate}
						minDate={DateTime.now().toJSDate()}
					/>
				</div>
				<div className="space-y-2">
					<Label>Data di fine</Label>
					<DatePicker disabled value={expirationDate} />
				</div>
			</div>

			<div className="mt-8 flex flex-col gap-2">
				<Button onClick={onConfirm} isLoading={isLoading}>
					Conferma
				</Button>
				<Button variant={'secondary'} onClick={props.onCancel} disabled={isLoading}>
					Annulla
				</Button>
			</div>
		</div>
	);
};
