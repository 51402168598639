import { Heading } from '@/components/heading';
import { useConan } from '@golee/gle-conan-tracker';
import { useNavigate } from 'react-router-dom';
import { NavigateBack } from '../../../../../components';
import { useOrgPerson } from '../../../../../context/org-person';
import { notifyDataChange } from '../../utils/notify-data-changed';
import { OtherForm } from './other-form';

export const ProfileOtherDataPage = () => {
	const navigate = useNavigate();
	const { trackEvent } = useConan();
	const { orgPerson, refresh: refreshOrgPersonContext } = useOrgPerson();

	const notifyChangesToOrganization = (updatedValues: { [key: string]: boolean | undefined }) => {
		const updatedValuesAsArray = Object.keys(updatedValues).filter(key => !!updatedValues[key]);

		if (updatedValuesAsArray.length) {
			notifyDataChange(orgPerson, updatedValuesAsArray as string[]);
			void trackEvent('family.orgPerson-changed-data-from-app', {
				values: updatedValuesAsArray.join(', '),
				section: 'other',
			});
		}
	};

	return (
		<div className="pb-4">
			<NavigateBack to={'/profile'} className="mb-4" />

			<Heading level={2} className="mb-4">
				Altri dati
			</Heading>

			<OtherForm
				orgPersonId={orgPerson.orgPersonId}
				season={orgPerson.season}
				onCancel={() => {
					navigate('/profile');
				}}
				onComplete={updatedValues => {
					notifyChangesToOrganization(updatedValues);
					refreshOrgPersonContext();
					navigate('/profile');
				}}
				initialValues={{
					attendedSchool: orgPerson.personMetadata.academics?.attendedSchool,
					schoolSpecialism: orgPerson.personMetadata.academics?.schoolSpecialism,
					iban: orgPerson.personMetadata.iban,
					identityDocumentExpirationDate: orgPerson.personMetadata.dates?.identityDocumentExpirationDate
						? new Date(orgPerson.personMetadata.dates?.identityDocumentExpirationDate)
						: undefined,
					identityDocumentNumber: orgPerson.personMetadata.identityDocument?.number,
					identityDocumentReleaseDate: orgPerson.personMetadata.identityDocument?.releaseDate
						? new Date(orgPerson.personMetadata.identityDocument.releaseDate)
						: undefined,
				}}
			/>
		</div>
	);
};
