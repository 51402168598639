import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { Logout } from '../../../components';
import { LayoutBase } from '../../../components/layout/layout';

export const RoleNotAllowedPage = () => {
	return (
		<LayoutBase>
			<Heading>Oops!</Heading>

			<p className="mt-4">Questa applicazione non è disponibile per il tuo utente.</p>

			<div className="my-4 flex flex-col items-center gap-3">
				<a href={window._env_.REACT_APP_AUTH_APP_URL}>
					<Button variant={'secondary'}>Vai alle tue applicazioni</Button>
				</a>

				<p className="my-2 text-sm text-muted-foreground">oppure</p>

				<Logout />
			</div>
		</LayoutBase>
	);
};
