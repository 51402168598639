import { DateTime } from 'luxon';
import { createContext, useContext, useState } from 'react';
import { RangeType } from '../../../../components/period-filter';

type PeriodContextType = {
	start: DateTime;
	end: DateTime;
	rangeType: RangeType;
	onChange: (start: DateTime, end: DateTime, rangeType?: RangeType) => void;
};

const PeriodContext = createContext<PeriodContextType | undefined>(undefined);

export const PeriodProvider = (props: { children: React.ReactNode }) => {
	const [rangeType, setRangeType] = useState<RangeType>('week');
	const [start, setStart] = useState<DateTime>(DateTime.now().startOf('week').startOf('day'));
	const [end, setEnd] = useState<DateTime>(DateTime.now().endOf('week').endOf('day'));

	const onChange = (_start: DateTime, _end: DateTime, rangeType?: RangeType) => {
		setRangeType(rangeType || 'week');
		setStart(_start);
		setEnd(_end);
	};

	return (
		<PeriodContext.Provider value={{ start, end, rangeType, onChange }}>{props.children}</PeriodContext.Provider>
	);
};

export const usePeriod = () => {
	const context = useContext(PeriodContext);

	if (!context) {
		throw new Error('usePeriod must be used within PeriodProvider');
	}

	return context;
};
