import { GoleeLogo } from '@/components/golee-logo';
import { Heading } from '@/components/heading';
import { Buffer } from 'buffer';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Currency, LoadingLayout } from '../../../components';
import { PaymentButton } from './payment-button';
import { usePaymentInfo } from './use-payment-info-public';

type Base64Result = {
	organizationId: string;
	trancheId: string;
};

const getPaymentInfoFromBase64 = (encoded: string): Base64Result => {
	try {
		const decoded: Base64Result = JSON.parse(Buffer.from(encoded, 'base64').toString());
		return decoded;
	} catch (e) {
		console.error('Error decode', encoded);
		throw new Error('unable-to-decode-base64');
	}
};

export const Pay = () => {
	const params: any = useParams();

	const [decodeError, setDecodeError] = useState<boolean>(false);
	const { loadPaymentInfo, paymentInfo, isLoading, error } = usePaymentInfo();

	const setup = () => {
		try {
			const { organizationId, trancheId } = getPaymentInfoFromBase64(params.encoded);

			if (organizationId && trancheId) {
				void loadPaymentInfo(organizationId, trancheId);
			}
		} catch (err) {
			setDecodeError(true);
		}
	};

	useEffect(() => {
		setup();
	}, []);

	if (isLoading) {
		return <LoadingLayout />;
	}

	if (error || decodeError || !paymentInfo) {
		return (
			<div className="mx-auto max-w-lg p-8">
				<Heading className="my-3">Si è verificato un errore</Heading>
				<p>
					Questo link è errato oppure non è più valido. Richiedi un nuovo link di pagamento alla tua società.
				</p>
			</div>
		);
	}

	return (
		<div className="mx-auto max-w-lg p-8">
			<div>
				<Club name={paymentInfo.clubName} logo={paymentInfo.clubLogo} />

				<p className="text-xl font-bold">{paymentInfo.reason}</p>
				<p className="text">{paymentInfo.denomination}</p>

				<div className="mb-4 mt-4 flex flex-col gap-1">
					<p className="text-3xl font-bold">
						<Currency value={paymentInfo.amount} />
					</p>
					<p className="text-sm text-gray-400">+ commissioni</p>
				</div>

				{paymentInfo.cashed ? (
					<p className="rounded border border-green-600 bg-green-200 p-2 text-center font-medium text-green-900">
						PAGATO
					</p>
				) : (
					<PaymentButton
						organizationId={paymentInfo.organizationId}
						trancheId={paymentInfo.trancheId}
						availablePaymentMethods={paymentInfo.availablePaymentMethods}
					/>
				)}
			</div>

			<div className="mt-4 border-t border-solid border-gray-100 pt-4 text-sm">
				<p>Puoi effettuare il pagamento utilizzando uno dei metodi disponibili.</p>
				<p>Il pagamento verrà ricevuto automaticamente dalla società che potrà inviarti la ricevuta.</p>
				<p className="mt-3 border-t border-solid border-zinc-100 pt-3 text-xs text-muted-foreground">
					<strong>Note</strong>: Al pagamento potrebbe essere applicata una commissione. Le commissioni
					possono variare in base alle impostazioni che ha configurato la società ed al metodo di pagamento
					selezionato. Naviga alla schermata successiva di pagamento per visualizzare il riepilogo
					dell'importo, delle commissioni e altri dettagli.
				</p>
			</div>

			<div className="mt-8 flex items-center justify-center">
				<GoleeLogo />
			</div>
		</div>
	);
};

const Club = ({ logo, name }: { logo?: string; name: string }) => {
	return (
		<div className="mb-4 flex items-center gap-3 border-b border-solid border-gray-100 pb-4">
			{logo && <img src={logo} style={{ width: 80 }} />}
			<p className="text-3xl font-bold">{name}</p>
		</div>
	);
};
