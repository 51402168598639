import Emoji from 'a11y-react-emoji';
import { ReasonWrapper } from './reason-wrapper';

const GoingNotes = ({ onClick, selectedReason }: { onClick: (name: string) => void; selectedReason?: string }) => {
	return (
		<div className="grid-row-1 grid grid-cols-2 items-stretch gap-4">
			<ReasonWrapper onClick={() => onClick('Arrivo tardi')} isActive={selectedReason === 'Arrivo tardi'}>
				<Emoji symbol="⏱" label="arriving-late" style={{ fontSize: 30 }} />
				<p className="text-sm text-gray-500">Arrivo tardi</p>
			</ReasonWrapper>
			<ReasonWrapper
				onClick={() => onClick('Mi serve un passaggio')}
				isActive={selectedReason === 'Mi serve un passaggio'}>
				<Emoji symbol="🚙" label="need-a-ride" style={{ fontSize: 30 }} />
				<p className="text-sm text-gray-500">Mi serve un passaggio</p>
			</ReasonWrapper>
			<ReasonWrapper onClick={() => onClick('Vado via prima')} isActive={selectedReason === 'Vado via prima'}>
				<Emoji symbol="🏃🏻" label="leaving-early" style={{ fontSize: 30 }} />
				<p className="text-sm text-gray-500">Vado via prima</p>
			</ReasonWrapper>
			<ReasonWrapper onClick={() => onClick('')} isActive={false}>
				<Emoji symbol="📝" label="other" style={{ fontSize: 30 }} />
				<p className="text-sm text-gray-500">Altro</p>
			</ReasonWrapper>
		</div>
	);
};

export default GoingNotes;
