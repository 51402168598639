import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './header';

export const Content = (props: { children: ReactNode }) => {
	return (
		<div id="main-content" className={'mx-auto mt-20 w-full max-w-2xl flex-col bg-white px-3 pb-4 lg:mt-20'}>
			{props.children}
		</div>
	);
};

export const MenuLayout = () => {
	return (
		<div>
			<Header hideNavigation={false} />
			<Content>
				<Outlet />
			</Content>
		</div>
	);
};

export const LayoutBase = (props: { children: ReactNode }) => {
	return (
		<div>
			<div>
				<Header hideNavigation />
				<Content>{props.children}</Content>
			</div>
		</div>
	);
};
