import { OrgPersonRole } from '../../context/org-person';
import { apiClient } from '../client';

export type Post = {
	_id: string;
	authorName: string;
	authorProfileId: string;
	authorRole: string;
	organizationId?: string;
	refGroups: string[];
	refCalendarEvents?: string[];
	isPublic: boolean;
	isPinned: boolean;
	title: string;
	content: { data: string; html?: string };
	reactions: PostReaction[];
	attachment?: { fileName: string; url: string };
	createdAt: string;
	type: string;
};

type PostReaction = {
	profileId: string;
	reaction: string;
	orgPersonId: string;
};

export const getPostsFeed = async (roleRestrictions: OrgPersonRole[], refGroups?: string[]) => {
	const { data: posts } = await apiClient('club').post<Post[]>(`/posts/feed`, {
		refGroups,
		roleRestrictions,
	});
	return posts;
};

export const setReaction = (id: string, reaction: string) =>
	apiClient('club').put(`/posts/${id}/reactions`, {
		reaction,
	});
