import { Badge } from '@/components/badge';
import { Button } from '@/components/button';
import { Card, CardContent } from '@/components/card';
import { useI18n } from '@gle/providers.i18n';
import { TObjTranche } from '@golee/gle-types';
import { ChevronRightIcon } from 'lucide-react';
import { OrgPerson } from '../../../context/org-person';
import { Currency } from '../../currency';

export const getTrancheReasonLabel = (
	messages: { [key: string]: string },
	tranche: Pick<TObjTranche, 'reason_label' | 'ref_reason'>
): string => {
	const label = tranche.ref_reason?.includes('common.')
		? messages[`tranche_reason.${tranche.ref_reason.substring(7)}`]
		: tranche.reason_label;

	return label || '';
};

export const PaymentStatusBadge = (props: { payment: TObjTranche }) => {
	const { messages } = useI18n();

	if (props.payment.amount.total_amount === 0) {
		return <Badge variant={'success'}>{messages['paid']}</Badge>;
	}

	if (props.payment.type === 'ENT') {
		if (props.payment.cashed) {
			return <Badge variant={'success'}>{messages['paid']}</Badge>;
		}

		return <Badge variant={'destructive'}>{messages['to_be_paid']}</Badge>;
	}

	if (props.payment.type === 'USC') {
		if (props.payment.cashed) {
			return <Badge variant={'success'}>{messages['cashed']}</Badge>;
		}

		return <Badge variant={'default'}>{messages['to_be_cashed']}</Badge>;
	}

	return <></>;
};

export const TrancheCard = ({
	tranche,
	...props
}: {
	tranche: TObjTranche;
	athlete: OrgPerson;
	onClick?: () => void;
}) => {
	const { messages } = useI18n();

	return (
		<Card onClick={props.onClick}>
			<CardContent className="space-y-4 p-4">
				<div>
					<div>
						<div className="mb-1">
							<PaymentStatusBadge payment={tranche} />
						</div>

						<p className="text-xl font-bold tracking-tighter">{getTrancheReasonLabel(messages, tranche)}</p>
						<p className="text-sm text-muted-foreground">
							{tranche.cashed_date ? (
								<>Pagato il: {tranche.cashed_date}</>
							) : (
								<>
									{tranche.expected_cashed_date
										? `Da pagare entro il: ${tranche.expected_cashed_date}`
										: ''}
								</>
							)}
						</p>
					</div>
				</div>

				<div className="flex items-center justify-between border-t border-solid border-muted pt-4">
					<p className="text-xl font-bold tracking-tighter">
						<Currency value={tranche.amount.total_amount} />
					</p>
					<Button variant={'secondary'} onClick={props.onClick}>
						{messages['details']} <ChevronRightIcon size={18} className="ml-2" />
					</Button>
				</div>
			</CardContent>
		</Card>
	);
};
