import { Button } from '@/components/button';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '@/components/drawer';
import { Input } from '@gle/base-ui.inputs.input';
import { useEffect, useState } from 'react';
import { useModal } from '../../../../../hooks/use-modal';
import GoingNotes from './going-notes';
import NotGoingNotes from './not-going-notes';

const NotesModal = ({
	presence,
	...props
}: {
	presence: null | boolean;
	note?: string;
	onSave: (note?: string) => void;
}) => {
	const modal = useModal();
	const [selectedReason, setSelectedReason] = useState<string | undefined>(undefined);

	const [note, setNote] = useState<string | undefined>(props.note);

	useEffect(() => {
		setNote(props.note);
	}, [props.note]);

	const onSelectReason = (reason?: string) => {
		setNote(reason);
		setSelectedReason(reason);
	};

	const onSave = () => {
		props.onSave(note);
		modal.closeModal();
	};

	return (
		<Drawer
			open={modal.isModalVisible}
			onOpenChange={status => {
				if (!status) modal.closeModal();
			}}>
			{note ? (
				<div className="flex items-center gap-2">
					<p className="text-sm text-muted-foreground">Note:</p>
					<p className="text-sm text-slate-600">{note}</p>
					<span
						className="cursor-pointer text-sm font-medium text-sky-700 hover:underline"
						onClick={modal.openModal}>
						Modifica
					</span>
				</div>
			) : (
				<div onClick={modal.openModal}>
					<p className="cursor-pointer text-sm font-medium text-sky-700 hover:underline">Aggiungi una nota</p>
				</div>
			)}

			<DrawerContent className="h-full">
				<DrawerHeader>
					<DrawerTitle>{!presence ? 'Non ci sono perchè...' : 'Aggiungi nota'}</DrawerTitle>
					<DrawerDescription>Specifica il motivo</DrawerDescription>
				</DrawerHeader>

				<div className="p-4">
					{!presence ? (
						<NotGoingNotes onClick={onSelectReason} selectedReason={selectedReason} />
					) : (
						<GoingNotes onClick={onSelectReason} selectedReason={selectedReason} />
					)}

					<div className="mt-4 flex flex-col gap-2">
						<Input
							value={note || ''}
							onChange={setNote}
							inputStyle={{ height: 38 }}
							placeholder="Oppure scrivi qui..."
						/>
						<Button onClick={onSave}>Salva</Button>
						<Button onClick={modal.closeModal} variant={'secondary'}>
							Annulla
						</Button>
					</div>
				</div>
			</DrawerContent>
		</Drawer>
	);
};

export default NotesModal;
