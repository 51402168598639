import { Button } from '@/components/button';
import { useToast } from '@/components/toast';
import { Input } from '@gle/base-ui.inputs.input';
import { useConan } from '@golee/gle-conan-tracker';
import { useState } from 'react';
import { addDocument } from '../../../../../api/documents/documents';
import { sendInstantNotification } from '../../../../../api/hermes/notifications';
import { PepaFile } from '../../../../../api/pepa/files';
import UploaderOnPepa from '../../../../../components/uploader/document-uploader';
import { useOrgPerson } from '../../../../../context/org-person/use-org-person';
import { RequestDocument } from '../request/request-document';

export type UploadOrRequestDocumentProps = {
	onUploadCompleted?: () => void;
	allowUpload?: boolean;
	allowRequest?: boolean;
};

export const UploadOrRequestDocument = (props: UploadOrRequestDocumentProps) => {
	const { orgPerson } = useOrgPerson();
	const { trackEvent } = useConan();
	const { toast } = useToast();

	const [fileUploaded, setFileUploaded] = useState<PepaFile>();

	const onFileUploaded = async () => {
		if (!fileUploaded) {
			return;
		}

		try {
			await addDocument({
				url: fileUploaded.public_url,
				name: fileUploaded.file_name,
				mimeType: fileUploaded.mime,
				ref_entity: orgPerson.orgPersonId,
			});

			setFileUploaded(undefined);

			props.onUploadCompleted?.();

			try {
				await sendInstantNotification(orgPerson.orgPersonId, orgPerson.organizationId, {
					occurrenceType: 'DocumentAttachedByOrgPerson',
					ownerType: 'OrgPersonDocuments',
					metadata: {
						documentName: fileUploaded.file_name,
						documentUrl: fileUploaded.public_url,
					},
					origin: 'system',
				});
			} catch (e: any) {
				console.error(`Error sending notification: ${e.message}`);
			}

			trackEvent('family.document-uploaded', { file_name: fileUploaded.file_name });
		} catch (e: any) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		}
	};

	return (
		<div className={'mb-4'}>
			{fileUploaded ? (
				<div className={'flex gap-4'}>
					<Input
						value={fileUploaded.file_name}
						onChange={file_name => setFileUploaded({ ...fileUploaded, file_name })}
					/>
					<Button onClick={onFileUploaded}>Invia</Button>
				</div>
			) : (
				<div className={'flex gap-4'}>
					{props.allowUpload && <UploaderOnPepa onFileUploaded={setFileUploaded} />}
					{props.allowRequest && <RequestDocument />}
				</div>
			)}
		</div>
	);
};
